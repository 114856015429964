import { useState, useEffect } from 'react';
import { useAccount } from 'wagmi'
import { ConnectButton } from '@rainbow-me/rainbowkit';

import { readContract, writeContract } from '@wagmi/core'
import contractABI from "./contracts/abi.json";

import Tool from './Tool';
import Minting from './Minting';

import './App.css';

function Main() {
    const [page, setPage] = useState('home');
    const [showFireworks, setFireworks] = useState(false);

    // their wallet
    const { address, isConnected } = useAccount();
    const walletAddress = (typeof address === 'string' ? address : '');
    const showAddress = (walletAddress.length > 0 && isConnected ? true : false);

    useEffect(() => {
        // fetchTokenIds(false);
    }, [address]);

    const fetchTokenIds = async (loadTokensPageAfter) => {
        // let ids = {};

        // console.log("fetchTokenIds");

        // if (walletAddress.length > 0) {
        //     console.log("Has valid address", walletAddress);

        //     // ids = await readContract({
        //     //     address: process.env.REACT_APP_CONTRACT_ADDRESS,
        //     //     abi: contractABI,
        //     //     functionName: 'getTokensOfOwner',
        //     // });
            
        //     console.log(ids);

        //     setTokenIds(ids);

        //     if (loadTokensPageAfter) {
        //         setPage('tokens');
        //     }
        // }

        // setTokenIds(ids);

        // if (ids.length > 0 && loadTokensPageAfter) {
        //     setPage('tokens');
        // }
    }

    const fireworkDisplay = () => {
        setFireworks(true);

        setTimeout(function () {
            setFireworks(false);
        }, 6000);
    };

    return (
        <div className="Screen">
            {showFireworks && (
                <div className="pyro">
                    <div className="before"></div>
                    <div className="after"></div>
                </div>
            )}
            <div className="Navigation">
                <div id="NavigationLinks">
                    {/* <div className={`NavigationLink ${page === 'home' ? 'Active' : ''}`} onClick={() => setPage('home')}>Home</div> */}
                </div>
                <div className="NavigationAlignment">
                    {showAddress && (<div><ConnectButton accountStatus="address" showBalance={false} /></div>)}
                </div>
            </div>
            <div className="Container">
                <div className="Content">
                    {page === 'home' && <Minting onMint={(tokens) => { 
                        fireworkDisplay(); 
                        // fetchTokenIds(true); 
                        // setPage('tokens');
                        //
                    }} />}
                </div>
            </div>
            <div className="Footer">
                <div className="FooterLinks">
                    <a className="FooterLink" href={process.env.REACT_APP_LINK_OS} target="_new" rel="noreferrer">
                        <img src="opensea.png" alt="OpenSea" />
                    </a>
                    <a className="FooterLink" href={process.env.REACT_APP_LINK_ETHERSCAN} target="_new" rel="noreferrer">
                        <img src="etherscan.png" alt="Etherscan" />
                    </a>
                    <a className="FooterLink" href="https://twitter.com/metazord_eth" target="_new" rel="noreferrer">
                        <img src="twitter.png" alt="twitter" />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Main;