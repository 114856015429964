export default (props) => {
    const padding = props.padding ?? 0;
    const basePixels = 24;
    const sizeScale = props.scale;
    const sizeNormal = basePixels * sizeScale;
    const sizeHalf = sizeNormal / 2;
    const canvasDimension = sizeNormal + (padding === 0 ? 0 : sizeScale * (padding * 2));

    // Our Seeding class to generate deterministically random numbers based on a given seed.
    class Seeding {
        constructor(seed) { this.seed = seed }
        nextFloat() {
            this.seed = (this.seed * 9301 + 49297) % 233280
            return this.seed / 233280
        }
        nextRange(min, max) { return min + this.nextFloat() * (max - min) }
    }

    const seeded = new Seeding(props.seed);

    const drawPattern = (xStart, yStart) => {
        const squares = [];
        const thresholdWhiteBg = 0.3833;
        const scaleTimes2 = 2 * sizeScale;
        const scaleTimes4 = 4 * sizeScale;
        const scaleTimes5 = 5 * sizeScale;
        const scaleTimes7 = 7 * sizeScale;
        const halfSizeBy5 = sizeHalf / 5;
        const sizeMinusScale = sizeNormal - sizeScale;

        for (let x = 0; x < 0 + sizeHalf; x += sizeScale) {
            const xMin = sizeMinusScale - x;
            const xPos = xMin + xStart;
            const xPlusStart = x + xStart;

            for (let y = 0; y < 0 + sizeHalf; y += sizeScale) {
                const sizeMinusScaleMinusY = sizeMinusScale - y;
                const yPos = sizeMinusScaleMinusY + yStart;
                const yPlusStart = y + yStart;
                let isWhite = seeded.nextRange(0, 1) > thresholdWhiteBg;

                if (!isWhite) {
                    const distanceFromEdge = Math.min(xMin, sizeMinusScaleMinusY);
                    const chanceOfWhite = (distanceFromEdge / halfSizeBy5) * 0.175;

                    if ((x < scaleTimes4 && y < scaleTimes2) && (seeded.nextRange(0, 1) > 0.27)) {
                        isWhite = true;
                    }
                    else if ((x < scaleTimes5 || y < scaleTimes7) && seeded.nextRange(0, 1) > chanceOfWhite) {
                        isWhite = true;
                    }

                    if (!isWhite) {
                        squares.push([xPlusStart, yPlusStart]);
                        squares.push([xPos, yPlusStart]);
                        squares.push([xPlusStart, yPos]);
                        squares.push([xPos, yPos]);
                    }
                }
            }
        }

        return squares;
    }

    // Canvas colors.
    const backgroundColor = '#fff';
    const foregroundColor = '#000';

    // Get an array of squares to add.
    const squares = drawPattern(padding * sizeScale, padding * sizeScale);

    // Output the SVG.
    return (
        <svg width={canvasDimension} height={canvasDimension} style={{ backgroundColor }}>
            {squares.map(([x, y]) => {
                return <rect key={`${x}-${y}`} x={x} y={y} width={sizeScale} height={sizeScale} fill={foregroundColor} />
            })}
        </svg>
    )
}