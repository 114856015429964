const getRandomName = () => {
    const adjectives = [
        "value", "chilly", "numerous", "sudden", "same", "purple", "possessive",
        "combative", "loose", "familiar", "jaded", "psychotic", "spotty", "macho",
        "exotic", "nonchalant", "horrible", "instinctive", "hilarious", "medical",
        "legal", "milky", "dry", "deranged", "inconclusive", "sparkling", "whole",
        "cheerful", "agreeable", "satisfying", "fierce", "helpless", "jazzy",
        "vengeful", "glorious", "glossy", "clear", "dreary", "swift", "dear",
        "unhealthy", "fortunate", "elastic", "eminent", "nervous", "thirsty",
        "scintillating", "confused", "aware", "quirky", "ill-informed", "mushy",
        "obedient", "colossal", "foolish", "stingy", "highfalutin", "knowledgeable",
        "psychedelic", "vulgar", "tawdry", "zany", "slippery", "steadfast",
        "barbarous", "threatening", "flat", "enthusiastic", "abhorrent", "gruesome",
        "simple", "breakable", "heartbreaking", "ill-fated", "uninterested", "soggy",
        "hapless"
    ];

    const nouns = [
        "protest", "cloth", "gold", "trust", "dock", "rabbits", "care", "purpose",
        "condition", "thread", "brass", "jellyfish", "spiders", "cart", "lamp",
        "shop", "attraction", "growth", "dream", "silk", "bucket", "rice", "price",
        "vacation", "peep", "achiever", "march", "attempt", "answer", "guarantee",
        "supply", "page", "blood", "bait", "territory", "tour", "request", "tank",
        "prose", "sky", "notebook", "hole", "locket", "wrench", "giant", "card",
        "seashore", "laborer", "face", "fork", "soup", "desk", "knee", "cow",
        "distribution", "collar", "cross", "business", "lunch", "power", "table",
        "print", "pencil", "ink", "pig", "preserve", "stir", "mice"
    ];

    const verbs = [
        "scream", "annoy", "realize", "bike", "advise", "gather", "succeed",
        "stare", "melt", "frighten", "calm", "greet", "memorize", "war", "destroy",
        "add", "amused", "repeat", "inform"
    ];

    const verb = verbs[Math.floor(Math.random() * verbs.length)];
    const adjective = adjectives[Math.floor(Math.random() * adjectives.length)];
    const noun = nouns[Math.floor(Math.random() * nouns.length)];

    return `${adjective} ${verb} ${noun}`;
};

export { getRandomName };