import React from 'react';
import Main from './Main';

import '@rainbow-me/rainbowkit/styles.css';
import {
    getDefaultWallets,
    RainbowKitProvider,
} from '@rainbow-me/rainbowkit';

import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { mainnet, goerli } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

let useChain = (process.env.REACT_APP_NETWORK == 'goerli') ? goerli : mainnet;

const { chains, publicClient } = configureChains(
    [useChain],
    [
        alchemyProvider({ apiKey: 'tdOzgy5p00OG8fC-YUlNM2x0vdouQSAE' }),
        publicProvider()
    ]
);
const { connectors } = getDefaultWallets({
    appName: 'Bitmasks',
    projectId: 'ee8dc239c814a02bb071027abaf06e95', // rainbow.me api key
    chains
});

const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient
})

function App() {
    return (
        <WagmiConfig config={wagmiConfig}>
            <RainbowKitProvider chains={chains}>
                <Main />
            </RainbowKitProvider>
        </WagmiConfig>
    );
}

export default App;
